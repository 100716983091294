"use client";

import {createContext, useMemo, useReducer, useEffect} from "react";

// Define initial state
const INITIAL_CART = [];
const INITIAL_STATE = {
    cart: INITIAL_CART
};

// Create the context
export const CSRCartContext = createContext({});

const reducer = (state, action) => {
    console.log(action.payload, 'hereCSR');
    switch (action.type) {
        case "CHANGE_CART_AMOUNT":
            let cartList = state.cart;
            let cartItem = action.payload;
            let exist = cartList.find(item => item.SKU === cartItem.SKU);

            if (cartItem.qty < 1) {
                const filteredCart = cartList.filter(item => item.SKU !== cartItem.SKU);
                return {...state, cart: filteredCart};
            }

            // If product already exists in cart
            if (exist) {
                const newCart = cartList.map(item => item.SKU === cartItem.SKU ? {...item, qty: cartItem.qty} : item);
                return {...state, cart: newCart};
            }

            // Add new item to cart
            return {...state, cart: [...cartList, cartItem]};

        case "UPDATE_VARIANT":
            const updatedItem = action.payload;
            const existingItemIndex = state.cart.findIndex(
                item => item.id === updatedItem.id && JSON.stringify(item.variantObject) === JSON.stringify(updatedItem.variantObject)
            );

            if (existingItemIndex !== -1) {
                const updatedCart = state.cart.map((item, index) =>
                    index === existingItemIndex ? {...item, qty: item.qty + updatedItem.qty} : item
                );

                return {
                    ...state,
                    cart: updatedCart.filter(item => !(item.SKU === updatedItem.SKU && item.variantObject !== updatedItem.variantObject))
                };
            } else {
                const updatedCart = state.cart.map(item => {
                    if (item.SKU === null && item.slug === updatedItem.slug) {
                        return {...item, ...updatedItem};
                    } else if (item.SKU === updatedItem.SKU) {
                        return {...item, ...updatedItem};
                    }
                    return item;
                });

                return {
                    ...state,
                    cart: updatedCart.filter(item => !(item.SKU === updatedItem.SKU && item.variantObject !== updatedItem.variantObject))
                };
            }

        case 'POPULATE_CART':
            return {
                ...state,
                cart: action.payload
            };

        default:
            return state;
    }
};

export default function CSRCartProvider({children}) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE, (initial) => {
        // Initialize state from localStorage if available
        const savedCart = localStorage.getItem("csr-cart");
        return savedCart ? {cart: JSON.parse(savedCart)} : initial;
    });

    // Sync cart data with localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("csr-cart", JSON.stringify(state.cart));
    }, [state.cart]);

    const contextValue = useMemo(() => ({state, dispatch}), [state, dispatch]);

    return <CSRCartContext.Provider value={contextValue}>{children}</CSRCartContext.Provider>;
}
