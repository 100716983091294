// import { openSans } from "app/layout";

import WebFont from 'webfontloader';

WebFont.load({
    google: {
        families: ['Roboto', 'Arimo', 'Inter', 'Open Sans', 'Oswald', 'Noto Sans', 'Butterfly Kids', 'Gabriela']
    }
});

export const fontSize = 14;
export const fontFamily = 'Noto Sans'
export const typography = {
  fontSize,
  htmlFontSize: 16,
  fontFamily,
  body1: {
    fontSize
  },
  body2: {
    fontSize
  }
};
